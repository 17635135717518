.Voiries{
   
    height: 95.3vh;
    padding-top: 2px;
    background-color: #5FAE96;
}
.logout2{
    margin: 5px;
    position: absolute;
}
.Htitle{
    position: absolute;
    left: 35%;
    color: white;
    font-weight: bold;
    font-size: large;
    margin-top: 5px;
}
.filtre1{
    width: 100%;
    height: 10vh;
    position: absolute;
    margin-bottom: 15px;
    margin-top: 40px;
    padding-left: 5px;
   }
.pictureDiapo{
       width: 40%;
       height: 80vh;
       position:relative;
       left:40%;
       top: 10.9vh;
   }

.carousel{
    height: auto;
}
.thumbs{
    display: none;
}
.slide{
    min-width: 100% !important;
}
.spinner1{
    position: relative;
    left: 50%;
    top:30vh;
}

.CoffretInfo{
    background-color: white;
    position: absolute;
    left:31.5%;
    top: 10.9vh;
    width: 60%;
    padding: 10px;
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
}