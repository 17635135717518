.AcceuilHome{
  display: flex;
   height: 90vh;
   padding-top: 2px;
   border-top-left-radius: 30px;
   border-bottom-left-radius: 30px;
   background-color:white;
   width: 98%;
}
.homenext{
    position: absolute;
    right: 10px;
    top: 8vh;
}
.homeInit{
  flex:1 ;
  width: 500px;
  height: 80vh;
  margin: 20px;
}

.homeFinal{
 flex: 1;
 width: 500px;
  height: 80vh;
  margin: 20px;
  
}

.homeData{
    position: relative;
    left: 25%;
    top: 15%;
    width: 60%;
    height: 80%;
    background-color:#EB8100 ;
    padding-top: 60px;
    color: white;
    font-weight: bolder;
}

.homeData li{
    margin-bottom: 10px;
}

.homeInit h3{
    position: absolute;
    transform: rotate(-90deg);
    top: 45vh;
    left: 10%;
    font-weight: bolder;
    
}
.homeFinal h3{
    position: absolute;
    transform: rotate(-90deg);
    top: 45vh;
    left: 58%;
    font-weight: bolder;
    
}

.homeInit img{
    position: absolute;
    top: 10vh;
    left: 13%;
    font-weight: bolder;
    z-index: 3;
    
}
.homeFinal img{
    position: absolute;
    top: 10vh;
    left: 62%;
    font-weight: bolder;
    z-index: 3;
    
}
