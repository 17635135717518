.PL{
    width: 100%;
    padding-top: 2px;
    background-color: #5FAE96;
}
body{
    overflow:hidden;
}
.logout2{
    margin: 5px;
}
.Htitle{
    position: absolute;
    left: 35%;
    color: white;
    font-weight: bold;
    font-size: large;
    margin-top: 5px;
}
.filtre{
 width: 100%;
 height: 10vh;
 margin-bottom: 5px;
 margin-top: 40px;
 padding-left: 5px;
}
.PLview{
    margin-top: 10px;
    display: flex;
    width: 100%;
    height: 77.15vh;
    background-color:  #5FAE96;
}
.Pltable{
  flex-grow: 1;
  overflow: auto;
  padding: 10px;
  background-color: white;
}
.Plpicture{
    width: 34%;
    flex-shrink: 1;
    overflow: auto;
    text-align: center;
   
}
.Plpicture img {
    margin: 10px;
}
.label{
    font-weight: bold;
    color: white;
}
.PLCompteur{
    position: absolute;
    left: 50px;
    top: 5px;
    font-weight: bold;
    color: white;
    font-size: large;
}
.Pltable tr {
    cursor: pointer;
 }

 .PLDetail{
    font-weight: bold;
 }
 .buttonPl{
     margin-top: 20px;
 }

 .imgDown{
     /* position: relative;
     top:55px;
     right: 110px;  */
 }

 .spinner{
     position: relative;
 }

 .Plpicture a{
    z-index: 10;
 }

 .repportDiv{
     position: absolute;
     width: 50%;
     height: 95vh;
     left: 25%;
     background-color: white;
     box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.658);
     z-index: 3;
     visibility: hidden;
 }

 .repportOut{
  margin: 10px;
  font-size: 2em;
  cursor: pointer;
 }

 .repportDwl{
  position: relative;
  margin-top: 10px;
  margin-right: 10px;
  float: right;
 }

 .repportView{
     width: 100%;
     overflow: auto;
     padding-top: 5px;
     padding-left: 5px;
     padding-right: 5px;
 }

 .repportTable{
     width: 95%;
     margin: 34px;
     
 }
 .repportTable td{
    font-size: larger;
    font-weight: bolder;
    padding-left: 5px;
 }

 .repportTable table, .repportTable th,.repportTable td {
    border: 1px solid black;
  }

  #map{
   width: 950px;
   height: 400px;
   margin-top: 15px;
   margin-left: 44px;
   margin-bottom: 15px;
  }

  .repportImg{
    display: flex;
    width: 95%;
    margin-top: 15px;
    margin-left: 34px;
    margin-bottom: 15px;
    align-items: center;
    justify-content: center;
  }

  .detTitle{
      text-decoration: underline;
  }

  .plUpdateBody{
    max-height: 380px;
    overflow-y: auto;
}

.tableUpdate{
    margin-left: 7.5%;
}