.footer1{
    display: block;
    width: 100%;
    height: 30px;
    background-color: #5FAE96;
    color: white;
}

.footer1 span{
    position: absolute;
    right: 5px;
    bottom: 5px;
}