.SyntheseInit{
    display: flex;
    height: 90vh;
    padding-top: 2px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    background-image: url('./etatInit.png');
    width: 98%;
}

.HAAinit{
    position: absolute;
    right: 10px;

}

.Fig1{
    border-radius: 50%;
    position: absolute;
    right: 25%;
    top:15vh;
}
.ConsoInit{
  position: absolute;
  width: 170px;
  height: 170px;
  background-color: white;
  border-radius: 50%;
  right: 23%;
  top:60vh;
  border:4px solid #031e4e;
}
.PLinit{
    position: absolute;
    width: 170px;
    height: 170px;
    background-color: white;
    border-radius: 50%;
    right: 45%;
    top:25vh;
    border:4px solid #EB8100;
}
.PanneInit{
    position: absolute;
    width: 170px;
    height: 170px;
    background-color: white;
    border-radius: 50%;
    right: 45%;
    bottom:10vh;
    border:4px solid #031e4e;
}
.Pinit{
    position: absolute;
    width: 170px;
    height: 170px;
    background-color: white;
    border-radius: 50%;
    right: 70%;
    bottom:25vh;
    border:4px solid #031e4e;
}
.InitTitle{
    color: white;
    font-size: 3em;
    position: absolute;
    left: 40%;
    top:15vh;
}
.ZoneTitle{
    color: #EB8100;
    font-size: 5em;
    position: absolute;
    left: 5%;
    top: 2vh;
    border-left: 20px solid #EB8100;
    padding-left: 10px;
}

.nextInit{
    position: absolute;
    right: 10px;
    bottom: 50px;
}

.filterInit{
    position: absolute;
    bottom: 50px;
    
}

.homeInit1{
    position: absolute;
    width: 50px;
    height: 50px;
    bottom: 50px;
    left: 120px;
}