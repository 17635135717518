.AcceuilFiltre{
    display: flex;
   height: 90vh;
   padding-top: 2px;
   border-top-left-radius: 30px;
   border-bottom-left-radius: 30px;
   background-image: url('./filterHome.jpeg');
   width: 98%;
   background-repeat: no-repeat;
   background-size: 1350px 607px;
}
.FiltreHAA{
    position: absolute;
    right: 10px;
}

.FiltreBack{
    position: absolute;
    left: 20px;
    top: 8vh;
    transform: rotate(180deg);
}
.filtreDiv{
    position: absolute;
    right: 20px;
    bottom: 40vh;
}

.FiltreLable{
    font-weight: bolder;
}

.infoText{
    position: absolute;
    left: 35%;
    bottom: 45vh;
    font-weight: bold;
    font-size: 2em;
}