.SlideBar{
    width: 40px;
    /* height: 90vh; */
    margin-top: 0;
    flex-shrink: 1;
    background-color: #EB8100;
}

.menuTitle{
    padding: 7px;
    background-color: #EB8100;
    position: absolute;
    top: 70px;
}

.menuTitle span{
    position: relative;
    bottom: 8px;
    left: 20px;
    color: white;
    z-index: 2;
}
.SlideBar .LinkInfo{
    position: relative;
    top: 80px;
    padding: 5px;
    color: white;
    cursor: pointer;
}
.SlideBar .LinkInfo:hover{
    background-color: #693a00;
}
.SlideBar span{
    position: relative;
    bottom: 8px;
    left: 20px;
    color: white;
    z-index: 2;
}
.LinkInfo span{
    visibility: hidden;
    position: absolute;
    bottom: 6px;
    left: 40px;
}

.menuTitle img{
    transition-duration: 0.6s;
    transition-property: transform;
}
.Entete{

}