.Coffret{
   height: 95.3vh;
   padding-top: 2px;
   background-color: #5FAE96;
}
.logout2{
    margin: 5px;
}
.Htitle{
    position: absolute;
    left: 35%;
    color: white;
    font-weight: bold;
    font-size: large;
    margin-top: 5px;
}

.filtre{
    width: 100%;
    height: 10vh;
    margin-bottom: 5px;
    margin-top: 40px;
    padding-left: 5px;
   }
   .PLview{
       margin-top: 10px;
       display: flex;
       width: 100%;
       height: 77.15vh;
       background-color:  #5FAE96;
   }
   .Pltable{
     flex-grow: 1;
     overflow: auto;
     padding: 10px;
     background-color: white;
   }
   .Plpicture{
       width: 34%;
       flex-shrink: 1;
       overflow: auto;
       text-align: center;
      
   }
   .Plpicture img {
       margin: 10px;
   }
   .label{
       font-weight: bold;
       color: white;
   }
   .CCPCompteur{
       position: absolute;
       left: 50px;
       top: 5px;
       font-weight: bold;
       color: white;
       font-size: large;
   }
   .Pltable tr {
       cursor: pointer;
    }
   
    .PLDetail{
       font-weight: bold;
    }
    .buttonPl{
        margin-top: 20px;
    }
   
    .imgDown{
        /* position: relative;
        top:55px;
        right: 110px;  */
    }
   
    .spinner{
        position: relative;
    }
   
    .Plpicture a{
       z-index: 10;
    }
