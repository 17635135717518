.App{
  display: block;
  
}
.Section{
  display: flex;
  background-color: #EB8100;
}
body{
  background-color: #EB8100;
}
