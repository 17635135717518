.Home{
    display: flex;
    height: 95.3vh;
    padding-top: 2px;
    text-align: center;
    align-items: center;
    background-color: #5FAE96;
    font-size: larger;
}

.fontB{
    font-size: 1.9em;
}
.logoAuthen{
    position: absolute;
    left: 43%;
}
.disconnect{
    position: relative;
    bottom: 48%;
    left: 97%;
    margin-right: 10px;
    margin-top: 5px;
    cursor: pointer;
}

.Home a img{
    margin: 30px;
    padding: 15px;
    background-color: #323C56;
    border-radius: 10px;
    box-shadow: 2px 5px 2px rgb(0, 0, 0);
}

.noClick{
    color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}
.contenue{
    position: relative;
    top: 30px;
}