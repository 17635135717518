.Report{
   flex-grow: 1;
   height: 90vh;
   padding-top: 2px;
   border-top-left-radius: 30px;
   border-bottom-left-radius: 30px;
   background-color: #EB8100;
}

.Report iframe{
   border-top-left-radius: 30px;
   border-bottom-left-radius: 30px;
}

.mask{
   position: absolute;
   width: 150px;
   height: 25px;
   background-color: #EAEAEA;
   bottom: 40px;
   left: 53px;
   display: none;
}