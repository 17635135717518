.SyntheseFinal{
    display: flex;
    height: 90vh;
    padding-top: 2px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    background-image: url('./etatfinal.png');
    width: 98%;
}
.Fig2{
    border-radius: 50%;
    position: absolute;
    left: 10%;
    top:20vh;
    z-index: 2;
}
.ConsoFinal{
  position: absolute;
  width: 170px;
  height: 170px;
  background-color: white;
  border-radius: 50%;
  right: 12%;
  top:40vh;
  border:4px solid #031e4e;
}
.PLfinal{
    position: absolute;
    width: 200px;
    height: 200px;
    background-color: white;
    border-radius: 50%;
    left: 13%;
    top:40vh;
    border:4px solid #EB8100;
}
.Pannefinal{
    position: absolute;
    width: 170px;
    height: 170px;
    background-color: white;
    border-radius: 50%;
    right: 20%;
    top:15vh;
    border:4px solid #031e4e;
}
.Pfinal{
    position: absolute;
    width: 200px;
    height: 200px;
    background-color: white;
    border-radius: 50%;
    right: 23%;
    bottom:10vh;
    border:4px solid #031e4e;
}
.InitTitle{
    color: white;
    font-size: 3em;
    position: absolute;
    left: 40%;
    top:15vh;
}
.ZoneTitle{
    color: #EB8100;
    font-size: 5em;
    position: absolute;
    left: 5%;
    top: 2vh;
    border-left: 20px solid #EB8100;
    padding-left: 10px;
}

.nextFinal{
    position: absolute;
    left: 20px;
    bottom: 50px;
    transform: rotate(180deg);
}

.filterFinal{
    position: absolute;
    right: 10px;
    bottom: 50px;
}

.autres{
    width: 40%;
    height: 50vh;
    position: absolute;
    top: 32vh;
    left: 25%;
}
.autres1{
    display: flex;
}
.autres2{
    display: flex;
    position: relative;
    left: 46px;
}
.autres3{
    display: flex;
    position: relative;
    left: 56px;
}
.autres4{
    display: flex;
    position: relative;
    left: 36px;
}
.autres5{
    display: flex;
}

.homeFinal1{
    position: absolute;
    width: 50px;
    height: 50px;
    bottom: 50px;
    right: 90px;
}
