.consoHome{
   display: flex;
   height: 90vh;
   padding-top: 2px;
   border-top-left-radius: 30px;
   border-bottom-left-radius: 30px;
   background-color:white;
   width: 98%;
}
.ConsoTitle{
   color: black;
   font-size: 3em;
   position: absolute;
   left: 40%;
   top:15vh;
}

.charts{
   position: relative;
   top: 24vh;
   height: 30vh;
   width: 90%;
}
.highcharts-container{
   height: 20vh;
   width: 80%;
}