.authen{
    display: block;
    width: 100%;
    height: 100vh;
    background-color: #EB8100;
    color: white;
}
.containt{
position: absolute;
top: 20vh;
left: 42%;
height: 40vh;
}

.header{
width: 100%;
height: 50px;

}
.header img{
    position: relative;
    left: 45%;
}
.form-control{
width: 300px !important;
}

.error span{
    color: rgb(245, 6, 6) !important;
    font-weight: bold;
  }

.logoAuthen1{
    position: absolute;
    top: 20px;
    left: 1%;
}
.containt a{
    color: white;
    text-decoration: none;
}