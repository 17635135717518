.Office{
   display: flex;
   height: 90vh;
   padding-top: 2px;
   background-color: #EB8100;
}
.Table{
flex-grow: 1;
width: 80%;
background-color: white;
overflow: auto;
white-space: nowrap;
margin: 5px;
padding: 10px;
}

.Table tr {
   cursor: pointer;
}

.formulaire{
 flex-shrink: 1;
 width: 26%;
 overflow: auto;
 padding: 5px;
}

.logout{
   position: absolute;
   top: 5px;
   left: 50px;

}

.compteData{
   position: absolute;
   top:10px;
   left:100px;
   font-weight: bold;
   color: white;
}