.Header{
    display: block;
    width: 100%;
    height: 34px;
    background-color:#EB8100;
    margin-bottom: 0;
    color: white;
}
.Entete{
    position: absolute;
    top: 5px;
    left: 5px;
}

.disconnect1{
    float: right;
    margin-right: 10px;
    margin-top: 5px;
    cursor: pointer;
}

.slogan{
    position: relative;
    left: 43%;
    top: 5px;
}

.user{
    position: absolute;
    right: 8%;
    top:5px;
}