.footer{
    display: block;
    width: 100%;
    height: 30px;
    background-color: #EB8100;
    color: white;
}

.footer span{
    position: absolute;
    right: 5px;
    bottom: 5px;
}